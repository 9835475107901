import React from "react";
import Seo from "../components/seo";
import Header from "../components/header/Header";
import CommonDoc from "../components/CommonDoc";
import CommonDocProfile from "../components/CommonDocProfile";
import ImgBunkacho from "../images/about-logo-01.png";
import ImgAitLogo from "../images/ait-logo.svg";

// markup
const AboutPage = (props) => {
  return (
    <main>
      <Seo lang={`en`} title={`About`} />

      <div>
        <Header lang={`en`} />
      </div>

      <CommonDoc>
        <h2>About this project</h2>
        <section>
          <p>
            The starting point of this website is the Artist-in-Residence
            Program titled "Liminal Space", which was held on the Internet from
            January to February 2021.
            </p><p>
            The Artist-in-Residence Program, which invites artists for a certain
            period of time to conduct research that leads to creative activities
            and to often create artworks, has been held not only in Japan but
            also in many other parts of the world, and has attracted not only
            artists but also curators who plan exhibitions, researchers,
            designers, writers, and others over decades. In recent years, they
            have supported the movement, temporary stay, and interaction of a
            wide range of people involved in the creation of art and culture.
        </p><p>
            However, in the wake of the new coronavirus epidemic that has been
            sweeping the world since the beginning of 2020, major travel
            restrictions have been imposed on movement across national borders
            (and sometimes even across more familiar provincial borders), and
            residency programs have been forced to think and work differently
            than in the past.
        </p><p>
            In this context, the artist Seira Uchida and the artist/designer
            Teresa Feldmann, who were selected from an open call, were invited
            to participate in a residency program organized in collaboration
            with Baltan Laboratories based in Eindhoven, the Netherlands and
            Arts Initiative Tokyo in Japan. Seira Uchida and Teresa Feldmann
            participated in the "Liminal Space," an artist-in-residence program
            that uses online as a "space" for research and interaction.
          </p>

          <p>
            Liminal Space, which is broadly translated as "a place of
            intermediate transformation" from one point to another, was not only
            an opportunity to reconsider the nature of the residency program
            itself, but also to highlight the "strains" of everyday life that
            have not been exposed until now, as the world experiences major
            changes almost in the same way. Uchida and Feldmann responded to
            this with their own sensitivities. During their “stay,” they were
            interested in the Japanese family system and the role of women in
            society at large, and compared it to the situation in the
            Netherlands.
          </p>

          <p>
            The global context of this liminal situation and the desire to weave
            "voices" and "experiences" during the pandemic not only from Japan
            and the Netherlands, but from everywhere, led to the launch of this
            report project. By outsourcing via online the texts which were
            originally written upon the residency, we asked new writers with
            whom we have had no previous relationship to trace the texts.
            Moreover, in this age of remote work and social distancing, we
            decided to use handwriting to trace the texts, which have been
            digitized by text files due to the spread of computers and the
            Internet nowadays.
            <br />
            This kind of outsourcing, which can be considered the ultimate in
            remote work regardless of location, has taken these texts to places
            we cannot easily visit today, including various locations in Japan,
            the US, Kenya, India, and Algeria. Within a few days, the
            handwritten texts came back from the remote workers with their own
            comments (I would also like to note that many of them were initially
            puzzled by this deviant request for their own opinions and
            experiences to share, compared to the regular outsourcing process,
            which I imagine is a step based on the one-way communication).
          </p>

          <p>
            In the Artist-in-Residence Program mentioned above, each program
            produced a booklet documenting the residency and describing the
            artist's impressions. In a departure from similar production
            methods, we have included these worldwide reports on our website.
          </p>
          <p>
            In addition to the interest in feminism shared by Uchida and
            Feldmann, we present here a small but solid collection of diverse
            voices and experiences during the pandemic.
          </p>
        </section>

        <section>
          <p>
            In spite of the continuing instability in all aspects of the world,
            we would like to express our gratitude to all the people involved
            for their great cooperation from the implementation of the residency
            program to the completion of the Worldwide Report Project. We would
            also like to take this opportunity to thank all the remote workers
            in Japan and abroad who willingly accepted our request and shared
            their experiences with us in the Worldwide Report Project.
          </p>
        </section>

        <hr />
        <section>
          <CommonDocProfile>
            <h3>Liminal Space –リミナル ・スペース</h3>
            <p>
              Artist-in-Residence Program January 15 through February 26, 2021
            </p>
            <dl>
              <dt>Artist</dt>
              <dd>
                <a
                  href={`https://www.a-i-t.net/friends/seira_uchida/`}
                  target={`_blank`}
                >
                  Seira Uchida
                </a>
                ,{" "}
                <a
                  href={`https://www.a-i-t.net/friends/teresa_feldmann/`}
                  target={`_blank`}
                >
                  Teresa Feldmann
                </a>
              </dd>
              <dt>Special thanks</dt>
              <dd>
                Yoshiko Shimada, Hikaru Fujii, Inte Gloerich, Ania Molenda, Air
                Brabant
              </dd>
              <dt>Co-organized by</dt>
              <dd>Baltan Laboratories</dd>
              <dt>Organized by</dt>
              <dd>Arts Initiative Tokyo [AIT]</dd>
            </dl>
          </CommonDocProfile>
        </section>
        <section>
          <CommonDocProfile>
            <h3>LIMINAL SPACE, WORLDWIDE REPORT</h3>
            <dl>
              <dt> Original text written by</dt>
              <dd>
                Yoshiko Shimada, Inte Gloerich, Ania Molenda, Linda Köke (Air
                Brabant), Teresa Feldmann, Seira Uchida, Olga Mink (Baltan
                Laboratories), Naoko Horiuchi (AIT), Shintaro Tokairin (AIT)
              </dd>
              <dt>Translated by</dt>
              <dd>Satoshi Ikeda, Jaime Humphreys, Shintaro Tokairin</dd>
              <dt>Hand written in English by</dt>
              <dd>ArchDesigner19, Joe, Emm, Umaxbayina?, AFREEN KHAN</dd>

              <dt>Hand written in Japanese by </dt>
              <dd>Atan, Mamiko, halu, mondschein, Yout</dd>

              <dt>Website development by </dt>
              <dd>Shunya Hagiwara</dd>

              <dt>Supported by</dt>
              <dd>Kento Terada, Blair Imani, Baltan Laboratories</dd>

              <dt>Organized by</dt>
              <dd>Arts Initiative Tokyo</dd>
            </dl>
          </CommonDocProfile>
        </section>
        <section>
          <CommonDocProfile>
            <h3>Baltan Laboratories</h3>
            <p>
              Baltan Laboratories, based in Eindhoven, the Netherlands initiates experimentation on the crossroads of art, design, science and technology, evoking inquisitive ideas and insights by bridging the gaps between disciplines. The lab functions as a collaborative mindset and network, connecting curious individuals and organizations. By placing art and design research at the core of its activities, Baltan explores the implications, promises and pitfalls of our technological society. Baltan translates these ideas into different outputs, including collaborations, events, workshops, storytelling, publications and expos, creating a space for reflection,
            </p>
            <p>
              <a href="https://www.baltanlaboratories.org" target={`_blank`}>https://www.baltanlaboratories.org</a>
            </p>

              <h3>Arts Initiative Tokyo</h3>
            <p>
              AIT is a non-profit organization founded in 2001 by six art curators and managers with the aim of creating a platform for learning, dialogue, and thinking open to anyone interested in contemporary art.
            </p>
            <p>
              As AIT approaches a quarter of a century since its establishment, we find ourselves in an age where each of us must consider an environmental crisis of immeasurable proportions, human rights issues, global epidemics and other multilayered challenges facing the world today. To this end, AIT will move forward into the future with a concern for the creation of a sustainable sociality through art and culture, using the experience and knowledge it has accumulated thus far while working together with collaborative partners around the world.
            </p>
            <p>
              <a href={`https://www.a-i-t.net/en/`} target={`_blank`}><img src={ImgAitLogo} alt="AIT Logo" style={{ width: `100px` }}/></a>
            </p>
          </CommonDocProfile>
        </section>
        <section>
        <p>
          Supported by the Agency for Cultural Affairs Government of Japan,
          Fiscal Year 2020 <br />
          <a href={`https://www.bunka.go.jp/`} target={`_blank`}><img src={ImgBunkacho} style={{ width: `80px` }} alt={`Bunkacho Logo`} /></a>
        </p>

      </section>
      </CommonDoc>
    </main>
  );
};

export default AboutPage;
