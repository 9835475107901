import React, { useState } from "react";
import * as css from "./CommonDocs.module.scss";

type Props = Readonly<{
  element: React.ReactNode;
}>;

// markup
const CommonDocProfile: React.FC<Props> = (props) => {

  return <div className={css.profile}>{props.children}</div>;
};

export default CommonDocProfile;
